import Frame from "common/Layout/Frame";
import { LayoutProps } from "common/Layout/types/Layout.types";
import { FC } from "react";
import { fetchLayoutData } from "../../lib";
import FourOFour from "Features/404";
import Meta from "Components/Meta";
type Props = {
  menus: LayoutProps;
};

const Custom404: FC<Props> = ({ menus }) => {
  return (
    <>
      <Meta />
      <FourOFour />
    </>
  );
};

export default Custom404;

export const getStaticProps = async (context: any) => {
  try {
    const publication = "abergavennychronicle.com";
    const props = {
      menus: await fetchLayoutData(publication),
    };

    return {
      props,
    };
  } catch (err) {
    console.log(err);
  }
  return { props: { data: "err" } };
};
