import Button from "Components/Button";
import CenterWrapper from "Components/CenterWrapper";
import { FouOFour, FourOFourWrapper, Message, NotFound } from "./404.styled";
import Link from "next/link";
import { useRouter } from "next/router";

const FourOFour = () => {
  const router = useRouter();
  return (
    <CenterWrapper>
      <FourOFourWrapper>
        <FouOFour>404</FouOFour>
        <NotFound>Page not found</NotFound>
        <Message>The page you requested could not be found.</Message>
        <div className="button_wrapper" style={{marginTop: '48px'}}>
          <Button
            handleClick={() => router.push("/")}
            buttonStyles="primary"
          >
            GO BACK HOME
          </Button>
        </div>
      </FourOFourWrapper>
    </CenterWrapper>
  );
};

export default FourOFour;
