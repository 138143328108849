import styled, { css } from "styled-components";

export const FourOFourWrapper = styled.div`
  display: flex;

justify-items: center;
  flex-direction: column;
  .button_wrapper {
      margin:  0 auto;
  }
`;
export const FouOFour = styled.h1`
  font-size: 200px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 230px;
  letter-spacing: 0em;

  margin-bottom: 0;
  color: ${({ theme }) => theme.default.color.primary};
`;
export const NotFound = styled.h1`
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0em;
  margin-top: 0;
  text-align: left;
  text-align: center;
`;
export const Message = styled.p`
  font-family:  ${({ theme }) => theme.default.fontFamily.secondaryFont};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  text-align: center;
`;
